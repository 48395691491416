.model {
    transition: all 5s ease !important;
    min-height: auto !important;
}

.model-close {
    display: none;
    visibility: hidden;
}

.model-open {
    display: block;
    visibility: visible;
}

.card-text {
    padding: 5px !important;
}

.card {
    min-height: auto !important;
}

.card:hover {
    cursor: pointer;
    background-color: rgb(232, 232, 232);
}

.RichTextEditor__root___2QXK- {
    /* max-width: 350px; */
    height: 350px;
    overflow-y: scroll;
}

.nav-list {
    margin-inline: 10px !important;
}

.nav-link {
    text-decoration: none;
    color: black;
}

.nav-btn:hover {
    background-color: #E6ECFF !important;
    border-radius: 10px;
    color: #003CFF;
    font-weight: bold !important;
}

.nav-logo {
    min-width: 110px !important;
    min-height: 110px !important;
}

.nav-link-icon {
    color: #7f95dc;
}

.nav-btn:hover .nav-link-icon {
    color: black;
}

.MuiDrawer-paperAnchorDockedLeft {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12) !important;
}

.MuiAppBar-root {
    box-shadow: none !important;
    border: 0px  !important;
    background-color: #F8F7FC !important;
}

.MuiToolbar-root {
    border: none !important;
}

.MuiTableCell-head {
    background-color: #E6ECFF !important;
}

.MuiTableCell-head-secondary {
    color: white !important;
    background-color: #2F3A4D !important;
}

thead .MuiTableCell-root:first-child {
    border-top-left-radius: 10px;
    /* border-bottom-left-radius: 10px; */
}

thead .MuiTableCell-root:last-child {
    border-top-right-radius: 10px;
    /* border-bottom-right-radius: 10px; */
}

.MuiTableRow-root.MuiTableRow-hover:hover {
    background-color: #0046FD !important;
    border: none !important;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12) !important;
}

.MuiTableRow-root.MuiTableRow-hover:hover > td {
    color: white !important;
}

.MuiTableRow-root.MuiTableRow-hover:hover > td a {
    color: white !important;
}

.order-card {
    border-radius: 10px !important;
    position: relative;
}

.card-bg-hover {
    position: absolute;
    top: 0;
    right: left;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: bottom left;
}

.card-bg-1 {
    background-image: url('./static/order2.png');
}
.card-bg-2 {
    background-image: url('./static/order1.png');
}
.card-bg-3 {
    background-image: url('./static/order2.png');
}
.card-bg-4 {
    background-image: url('./static/order.png');
}
.card-bg-5 {
    background-image: url('./static/order.png');
}

.order-card-1 {
    background: linear-gradient(98.86deg, #6BAAFC 0%, #305FEC 100%);
}

.order-card-2 {
    background: linear-gradient(98.86deg, #EF5E7A 0%, #D35385 100%);
}

.order-card-3 {
    background: linear-gradient(98.86deg, #D623FE 0%, #A530F2 100%);
}

.order-card-4 {
    background: linear-gradient(98.86deg, #F2CB6C 0%, #FFB402 100%);
}

.order-card-5 {
    background: linear-gradient(98.86deg, #90f26c 0%, #02ff20 100%);
}

.tag-container .MuiBox-root {
    padding-inline: 0px !important;
}

.MuiTableContainer-root {
    margin-inline: 0px !important;
}

.card-media {
    background-color: white !important;
    border-radius: 10px;
    height: 380px;
    object-fit: contain !important;
}

.order-card-media {
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12) !important;
}

.csv-btn {
    border: 1px solid #1b5e20;
    background-color: #1b5e20;
    color: white;
    text-decoration: none;
    padding: 0.9rem 0.5rem;
    border-radius: 5px;
    margin-right: 1rem;
}

.csv-btn:hover {
    background-color: transparent;
    color: black;
}

.csv-btn:active, .csv-btn:focus {
    background-color: transparent;
    color: #1b5e20;
}

.exportBtn {
    text-decoration: none;
}